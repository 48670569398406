html {
    position: relative;
    min-height: 100%;
}

#main a:visited {
  color: revert;
}

body {
    margin-bottom: 150px;
}

img {
    max-width: 100%;
    height: auto;
}

#footer {
    position: absolute;
    bottom: 0;
    padding-top: 50px;
    height: 150px;
}

.project-logos {
    height: 50px;
}

.note td, .note th {
    border: solid;
    border-width: 1px;
    padding: 5px;
}

.note {
    padding-bottom: 30px;
}

.note img {
    display: block;
    margin: 0 auto;
    text-align: center;
    max-width: 50%;
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
}

blockquote::before {
    color: #ccc;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

.image-gallery img {
    width: 150px;
    display: inline;
    margin: 20px;
}
